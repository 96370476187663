import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useState, useEffect } from "react";
import MemberForm from "../memberForm/memberForm";
import "./memberTabs.scss";
import DropdownList from "../../dropdowns/dropdownList/dropdownList";
import WebPortalUserList from "../../webPortalUsers/webPortalUsersList/webPortalUserList";
import ContactList from "../../MIIAContacts/contactList/contactListOfSingleMember";
import QuickLinkList from "../../quickLink/quickLinkList/quickLinkList";
import ButtonInHomeList from "../../buttonsInHome/buttonInHomeList/buttonInHomeList";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as memberAction from "../../../../redux/actions/member.action";
import * as linkAction from "../../../../redux/actions/link.action";
import PurchasedProducts from "../../pages/PurchasedProducts/purchasedProducts";
import WebPortalOptions from "../../webPortalOptions/webPortalOptions";
import { useNavigate } from "react-router-dom";
import ModalConfirmation from "../../../modalconfirmation/ModalConfirmation";
import { useTranslation } from "react-i18next";
import * as userAction from "../../../../redux/actions/user.action";

const MemberTabs = () => {
  const dispatch = useDispatch();
  const { entityId, currentTab } = useParams();
  const navigate = useNavigate();
  const [entityName, setEntityName] = useState("");
  const [modalAction, setModalAction] = useState(null);
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const { memberDetails } = useSelector((state) => ({
    memberDetails: state.member.memberDetails,
  }));

  const handleTabSelect = (key) => {
    navigate(`/members/form/${entityId}/${key}`);
  };


  // on start clear errors
  useEffect(() => {
    userAction.clearErrors(dispatch);
  }, [dispatch]);
  

  const initialParams = {
    fieldTofilter: "name",
    filter: "",
    fieldToSort: "id",
    sortDirection: "asc",
    page: 1,
    pageSize: 8,
    member: entityId,
  };

  useEffect(() => {
    if (entityId) {
      //If we have an id then load data
      memberAction.fetchDetails(dispatch, entityId);
      linkAction.fetchAll(dispatch, initialParams);
    }
  }, [dispatch, entityId]);

  return (
    <div className="member-tab-container">
      <div className="member-header">
        <div className="member-header-main">
          <span className="member-tab-title">{`Editing ${
            memberDetails?.name || "member"
          }`}</span>
          <span
            onClick={() => {
              setShow(true);
              setEntityName(memberDetails?.name);
              setModalAction(() => () => {
                memberAction.remove(dispatch, entityId);
                setShow(false);
                navigate("/members");
              });
            }}
            className="member-tab-delete-action"
          >
            Delete Member
          </span>
        </div>
      </div>
      <Tabs activeKey={currentTab} onSelect={handleTabSelect}>
        <Tab eventKey="general" title="GENERAL">
          <MemberForm member={entityId} />
        </Tab>
        <Tab eventKey="dropdowns" title="DROPDOWNS">
          <DropdownList member={entityId} />
        </Tab>
        <Tab eventKey="webportalusers" title="WEB PORTAL USERS">
          <WebPortalUserList member={entityId} />
        </Tab>
        <Tab eventKey="quickaccess" title="QUICK ACCESS">
          <QuickLinkList member={entityId} />
        </Tab>
        <Tab eventKey="buttonsinhome" title="BUTTONS IN HOME">
          <ButtonInHomeList member={entityId} />
        </Tab>
        <Tab eventKey="purchasedproducts" title="PRODUCTS">
          <PurchasedProducts member={entityId} />
        </Tab>
        <Tab eventKey="portalsettings" title="WEB PORTAL">
          <WebPortalOptions member={entityId} />
        </Tab>
        <Tab eventKey="portalContacts" title="MIIA CONTACTS">
          <ContactList memberId={entityId} memberName={memberDetails?.name} />
        </Tab>
      </Tabs>
      <ModalConfirmation
        title={t("modalconfirmation.titleDelete")}
        question={t("modalconfirmation.deletequestion", {
          entity: entityName || "this entity", action: "delete"
        })}
        mainAction={modalAction}
        secondaryAction={() => { }}
        mainButton={t("modalconfirmation.yesdelete")}
        secondaryButton={t("modalconfirmation.cancel")}
        handleShow={setShow}
        show={show}
      />
    </div>
  );
};

export default MemberTabs;
