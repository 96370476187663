import axios, { authHeader } from "../../services/HTTPService";
import * as action from "../reducers/contactReducer";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchAllExcludingAMember = async ({ dispatch, memberId, params }) => {
  dispatch(action.setListLoading(true));
  return axios.get(API_URL + `/api/contacts/findContactsExcludingMember/${memberId}`, {
    headers: authHeader(), 
    params,
  })
    .then(({ data }) => {
      const meta = data?.meta;
      dispatch(action.setListLoading(false));
      dispatch(action.setContactList({ data }));
      dispatch(action.setMetadata({ meta }));
    })
    .catch((error) => {
      dispatch(action.setListLoading(false));
      dispatch(
        action.setContactError({ error: error?.response?.data?.error?.message })
      );
    });
}

export const fetchAll = async (
  dispatch,
  {
    fieldTofilter,
    filter,
    fieldToSort,
    sortDirection,
    page,
    pageSize,
    memberFilters,
    includeContacsForAllMembers,
  } = {
    fieldTofilter: "full_name",
    filter: "",
    fieldToSort: "full_name",
    sortDirection: "asc",
    page: 1,
    pageSize: 8,
    memberFilters: { all: true, members: [], groups: [] },
    includeContacsForAllMembers: false,
  }
) => {
  dispatch(action.setListLoading(true));

  return axios
    .get(API_URL + `/api/contacts/backoffice/search`, {
      headers: authHeader(),
      params: {
        fieldTofilter,
        filter,
        fieldToSort,
        sortDirection,
        page,
        pageSize,
        memberFilters,
        includeContacsForAllMembers,
      },
    })
    .then(({ data }) => {
      const meta = data?.meta;
      dispatch(action.setContactList({ data }));
      dispatch(action.setMetadata({ meta }));
      dispatch(action.setListLoading(false));
    })
    .catch((error) => {
      dispatch(
        action.setContactError({ error: error?.response?.data?.error?.message })
      );
      dispatch(action.setListLoading(false));
    });
};

export const fetchAllContactsOfAMember = async (dispatch, params) => {
  console.log('params', params)
  const groupFilters = {};
  const groupsOfMember = params?.memberFilters?.groups;
  groupsOfMember.forEach((group, index) => groupFilters[`filters[$or][${index + 1}][location_groups][id]`] = group)
  const filterToSearch = {};
  if (params?.filter) filterToSearch[`filters[${params.fieldTofilter}][$containsi]`] = params.filter;
  return axios
    .get(API_URL + `/api/contacts`, {
      headers: authHeader(),
      params: {
      'sort[0]':  `${params.fieldToSort}:${params.sortDirection}`,
      ...filterToSearch,
        'filters[$or][0][locations][id]': params.memberFilters.members[0],
        ...groupFilters,
      }
    })
    .then(({ data }) => {
      const meta = data?.meta;
      dispatch(action.setContactList({ data }));
      dispatch(action.setMetadata({ meta }));
      dispatch(action.setListLoading(false));
    })
    .catch((error) => {
      dispatch(
        action.setContactError({ error: error?.response?.data?.error?.message })
      );
      dispatch(action.setListLoading(false));
    });
};

export const fetchDetails = async (dispatch, id) => {
  return axios
    .get(API_URL + `/api/contacts/${id}`, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setContactDetail({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setContactError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const create = async (dispatch, body) => {
  return axios
    .post(API_URL + "/api/contacts", body, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setContact({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setContactError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const update = async (dispatch, id, body) => {
  return axios
    .put(API_URL + `/api/contacts/${id}`, body, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setContact({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setContactError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const remove = async (dispatch, id, params, setContactDeletedSuccessMessage = () => {}) => {
  return axios
    .delete(API_URL + `/api/contacts/${id}`, { headers: authHeader() })
    .then(async () => {
      await fetchAll(dispatch, params);
      setContactDeletedSuccessMessage("Contact deleted successfully");
    })
    .catch((error) => {
      dispatch(
        action.setContactError({ error: error?.response?.data?.error?.message })
      );
    });
};


export const linkMember = async ({
  dispatch,
  contactId,
  memberId,
  contactParams,
  setModalSuccessMessage,
  setModalErrorMessage,
 }) => {
  return axios
    .post(API_URL + `/api/contacts/linkMember/${contactId}`, { memberId }, { headers: authHeader() })
    .then(async () => {
      await fetchAllExcludingAMember({ dispatch, memberId, contactParams })
      setModalSuccessMessage("Contact added to member successfully");
    })
    .catch((error) => {
      setModalErrorMessage(error?.response?.data?.error?.message );
      dispatch(
        action.setContactError({ error: error?.response?.data?.error?.message })
      );
    });
};


export const unlinkMember = async ({ 
  setErrorMessage,
   setSuccessMessage,
   dispatch,
   contactId,
   memberId,
   contactParams,
   }) => {
  return axios
    .post(API_URL + `/api/contacts/unlinkMember/${contactId}`, { memberId } , { headers: authHeader() })
    .then(async () => {
      await fetchAllContactsOfAMember(dispatch, contactParams);
      setSuccessMessage("Contact removed from member successfully");
    })
    .catch((error) => {
      setErrorMessage(error?.response?.data?.error?.message );
      dispatch(
        action.setContactError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const fetchContactRoles = async (dispatch) => {
  return axios
    .get(API_URL + "/api/contact-roles/options", { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setContactRoles({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setContactError({ error: error?.response?.data?.error?.message })
      );
    });
}