import * as action from "../reducers/memberGroupReducer";
import axios, { authHeader } from "../../services/HTTPService";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchAll = async (
  dispatch,
  {
    fieldTofilter,
    filter,
    fieldToSort,
    sortDirection,
    page,
    pageSize,
    isFilter,
  } = {
    fieldTofilter: "title",
    filter: "",
    fieldToSort: "id",
    sortDirection: "asc",
    page: 1,
    pageSize: 8,
  }
) => {
  dispatch(action.setListLoading(true));

  return axios
    .get(API_URL + `/api/location-groups/backoffice/search`, {
      headers: authHeader(),
      params: {
        fieldTofilter,
        filter,
        fieldToSort,
        sortDirection,
        page,
        pageSize,
        isFilter,
      },
    })
    .then(({ data }) => {
      const meta = data?.meta;
      if (!isFilter) {
        dispatch(action.setMemberGroupList({ data }));
      } else {
        dispatch(action.setMemberGroupFilterList({ data }));
      }
      dispatch(action.setMetadata({ meta }));
      dispatch(action.setListLoading(false));
    })
    .catch((error) => {
      dispatch(
        action.setMemberGroupError({
          error: error?.response?.data?.error?.message,
        })
      );
      dispatch(action.setListLoading(false));
    });
};

export const fetchDetails = async (dispatch, id) => {
  return axios
    .get(API_URL + `/api/location-groups/${id}`, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setMemberGroupDetail({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setMemberGroupError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const getMemberGroupsOfALocation = async (dispatch,locationId) => {
  return axios
    .get(API_URL + `/api/location-groups/getLocationsGroupsOfSpecificLocation/${locationId}`, { headers: authHeader() })
    .then(({ data }) => {
      console.log('data', data)
      return data?.data?.map(l => l.id)
    })
    .catch((error) => {
      dispatch(
        action.setMemberGroupError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const setSlideDuration = async (dispatch, body) => {
  return axios
    .put(API_URL + `/api/carrousel-slides/time-shown`, body, {
      headers: authHeader(),
    })
    .then(({ data }) => {
      dispatch(action.setSlideDuration({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setMemberGroupError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const create = async (dispatch, body) => {
  return axios
    .post(API_URL + "/api/location-groups", body, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setMemberGroup({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setMemberGroupError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const update = async (dispatch, id, body) => {
  return axios
    .put(API_URL + `/api/location-groups/${id}`, body, {
      headers: authHeader(),
    })
    .then(({ data }) => {
      dispatch(action.setMemberGroup({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setMemberGroupError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const remove = async (dispatch, id, params) => {
  return axios
    .delete(API_URL + `/api/location-groups/${id}`, { headers: authHeader() })
    .then(async () => {
      await fetchAll(dispatch, params);
    })
    .catch((error) => {
      dispatch(
        action.setMemberGroupError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const fetchSelectOptions = async (dispatch, { isDropdown } = {}) => {
  return axios
    .get(API_URL + `/api/location-groups/options`, { headers: authHeader() })
    .then(({ data }) => {
      if (isDropdown) {
        dispatch(action.setMemberGroupSelectList({ data }));
      } else {
        dispatch(action.setMemberGroupList({ data }));
      }
    })
    .catch((error) => {
      dispatch(
        action.setMemberGroupError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};
