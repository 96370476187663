import { Modal, Button } from "react-bootstrap";
import * as memberGroupAction from "../../../src/redux/actions/memberGroup.action";
import Form from "react-bootstrap/Form";
import FileInput from "../fileInput/fileInput";
import Select from "react-select";
import { getTheme } from "../../helpers/select.theme";
import { customStyles } from "../../helpers/select.custom";
import "./modalOptionForm.scss";
import { useEffect, useState } from "react";
import { formatGroupLabel, getMemberOptions, getOnlyMemberGroupOptions, getOnlyMemberGroupOptionsAndAllMembers } from "../../helpers/select";
import { useDispatch, useSelector } from "react-redux";
const _ = require("lodash");

const ModalOptionForm = ({
  show,
  handleShow,
  categoryOptions,
  context,
  formik: {
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    setFieldValue,
  },
}) => {
  const { name, level, firstLevelIndex, secondLevelIndex, thirdLevelIndex } = context;
  const [memberOptions, setMemberOptions] = useState([]);
  const dispatch = useDispatch();


  const {
    memberList,
    memberGroupList,
  } = useSelector((state) => ({
    error: state.article.articleError,
    memberList: state.member.memberSelectList,
    memberGroupList: state.memberGroup.memberGroupSelectList,
  }));
  
  useEffect(() => {
    memberGroupAction.fetchSelectOptions(dispatch, { isDropdown: true });
  }, [dispatch]);

  useEffect(() => {
    setMemberOptions(getOnlyMemberGroupOptionsAndAllMembers(memberGroupList, memberList));
  }, [JSON.stringify(memberGroupList), JSON.stringify(memberList)]);


  const optionsForPreviousReport = [
    { value: "current", label: "Current" },
    { value: "current_year", label: "Current Year" },
    { value: "second_most_recent", label: "Second Most Recent" },
    { value: "previous_month", label: "Previous Month" },
    { value: "previous_bimester", label: "Previous Bimester" },
    { value: "previous_quarter", label: "Previous Quarter" },
    { value: "previous_semester", label: "Previous Semester" },
    { value: "previous_year", label: "Previous Year" },
  ];

  const getValue = (level) => {
    const valueMapping = {
      1: values?.options[firstLevelIndex],
      2: values?.options[firstLevelIndex]?.second_level_options[secondLevelIndex],
      3: values?.options[firstLevelIndex]?.second_level_options[secondLevelIndex]?.third_level_options[thirdLevelIndex],
    };
    return valueMapping[level];
  }

  const getLevelName = (level) => {
    const nameMapping = {
      1: `options[${firstLevelIndex}]`,
      2: `options[${firstLevelIndex}].second_level_options[${secondLevelIndex}]`,
      3: `options[${firstLevelIndex}].second_level_options[${secondLevelIndex}].third_level_options[${thirdLevelIndex}]`,
    };

    return nameMapping[level];
  };

  const clearLevel = (level) => {
    const valueToAssign = {
      default_type: "",
      default_link: "",
      default_category: "",
      default_file: null,
    };

    Object.entries(valueToAssign).forEach(([key, value]) => {
      setFieldValue(`${getLevelName(level)}.${key}`, value);
    });
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => {
        handleShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{name}</Modal.Title>
        <Button
          onClick={() => {
            clearLevel(level);
          }}
          className="form-action form-action-primary"
          variant="outline-primary"
        >
          Clear
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form className="modal-option-value-form">
          <div className="modal-option-value-form-layout">
            <div className="form-input">
              <Form.Label>Type</Form.Label>
              <Select
                className="modal-option-value-form-select"
                theme={(theme) => getTheme(theme)}
                closeMenuOnSelect={true}
                value={getValue(level)?.default_type}
                onChange={(value) => {
                  setFieldValue(`${getLevelName(level)}.default_type`, value);
                }}
                options={[
                  { value: "internal_link", label: "Internal Link" },
                  { value: "external_link", label: "External Link" },
                  { value: "file", label: "File" },
                  { value: "email", label: "Send an email" },
                  { value: "ftp", label: "Download a Report" },
                ]}
              />
            </div>
            {getValue(level)?.default_type?.value === "file" && (
              <div className="form-input">
                <FileInput
                  title="Browse a File"
                  allowedTypes={"PDF, DOC, XLS"}
                  value={getValue(level)?.default_file}
                  customFieldName={`${getLevelName(
                    level
                  )}.default_file`}
                  setFieldValue={setFieldValue}
                  errorMessage={errors}
                />
              </div>
            )}
            {["internal_link", "external_link", "email"].includes(
              getValue(level)?.default_type?.value
            ) && (
                <div className="form-input">
                  <Form.Label>
                    {getValue(level)?.default_type?.value === "email"
                      ? "Email"
                      : "URL"}
                  </Form.Label>
                  <Form.Control
                    name={`${getLevelName(level)}.default_link`}
                    value={getValue(level)?.default_link}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={errors.default_link && touched.default_link}
                  />
                  <Form.Control.Feedback default_type="invalid">
                    {errors.default_link}
                  </Form.Control.Feedback>
                </div>
              )}
            {getValue(level)?.default_type?.value === "ftp" && (
              <div className="form-input">
                <Form.Label>Available Reports</Form.Label>
                <Select
                  className="modal-option-value-form-select"
                  theme={(theme) => getTheme(theme)}
                  closeMenuOnSelect={true}
                  value={getValue(level)?.default_category}
                  onChange={(value) => {
                    setFieldValue(
                      `${getLevelName(level)}.default_category`,
                      value
                    );
                  }}
                  options={categoryOptions}
                />
              </div>
            )}
            {getValue(level)?.default_type?.value === "ftp" && (
              <div className="form-row"
                style={{ flexDirection: "column" }}
              >
                < Form.Label>Period</Form.Label>
                < Select
                  className="modal-option-value-form-select"
                  theme={(theme) => getTheme(theme)}
                  closeMenuOnSelect={true}
                  value={getValue(level)?.report_period ? optionsForPreviousReport.find(option => option.value === getValue(level).report_period) : { value: "current", label: "Current" }}
                  onChange={(optionSelected) => {
                    console.log('optionSelected', optionSelected)
                    console.log('getLevelName(level)', getLevelName(level))
                    console.log('getValue(level)', getValue(level))
                    setFieldValue(`${getLevelName(level)}.report_period`, optionSelected.value);
                  }}
                  options={optionsForPreviousReport}
                />
              </div>
            )}
            {getValue(level)?.default_type?.value === "ftp" && (
              <div className="form-row"
                style={{ flexDirection: "column" }}
              >
                <Form.Label>Published in</Form.Label>
                <Select
                  theme={(theme) => getTheme(theme)}
                  className="form-select-custom"
                  isMulti
                  styles={customStyles}
                  value={_.isEmpty(getValue(level)?.location_groups) ?
                    [{ label: "All Members", value: "all", title: "All Members" }]
                    :
                    getValue(level)?.location_groups.map((location) => {
                      return {
                        label: location.title,
                        id: location.id,
                        value: location.id,
                      };
                    })}
                  onChange={(option, action) => {
                    console.log('action', action)
                    console.log('option', option)
                    const newLocationGroups = option
                      // Remove all option if it exists
                      .filter((location) => location.value !== "all")
                      .map((location) => {
                        return {
                          id: location.id,
                          title: location.label,
                        };
                      }
                      );
                    if (action?.option?.value === "all") {
                      setFieldValue(`${getLevelName(level)}.location_groups`, []);
                      return;
                    }
                    // do not save if the selected option already exists
                    const selectedLabel = action?.option?.label;
                    if (option.filter((location) => location.label === selectedLabel).length > 1) {
                      return;
                    }
                    setFieldValue(`${getLevelName(level)}.location_groups`, newLocationGroups);
                  }}
                  closeMenuOnSelect={false}
                  onBlur={handleBlur}
                  options={memberOptions}
                  isInvalid={errors.memberGroup && touched.memberGroup}
                  formatGroupLabel={formatGroupLabel}
                  getOptionLabel={(option) => (
                    <div className="form-select-custom-label">
                      <span className="form-select-custom-label-primary">
                        {option.label}
                      </span>
                      <span className="form-select-custom-label-secondary">
                        {option.secondaryLabel}
                      </span>
                    </div>
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.member}
                </Form.Control.Feedback>
              </div>
            )}
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalOptionForm;
