import { Alert, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as contactAction from "../../../../redux/actions/contact.action";
import * as memberGroupAction from "../../../../redux/actions/memberGroup.action";
import { formatDate } from "../../../../helpers/date";
import List from "../../../list/list";
import { useTranslation } from "react-i18next";

const contactFields = [
  //list will sort by the first element by default
  "full_name",
  "department",
  "contact_role.title",
  "email",
  "phone_number",
  "publishedAt",
];

const ContactList = ({ memberId, memberName }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const [modalSuccessMessage, setModalSuccessMessage] = useState('')
  const [modalErrorMessage, setModalErrorMessage] = useState('')

  const { contacts, contactMetadata, contactListLoading } = useSelector((state) => ({
    contacts: state.contact.contactList,
    contactMetadata: state.contact.contactMetadata,
    contactListLoading: state.contact.listLoading,
  }));


  const getParams = async () => {
    const locationsGroupsIds = await memberGroupAction.getMemberGroupsOfALocation(dispatch, memberId) || [];
    return {
      filter: "",
      fieldToSort: "id",
      sortDirection: "desc",
      page: 1,
      pageSize: 10,
      includeContacsForAllMembers: false,
      fieldTofilter: "full_name",
      memberFilters: { members: [memberId], groups: locationsGroupsIds },
    }
  }

  const cleanAlerts = () => {
    setErrorMessage('');
    setSuccessMessage('');
    setModalSuccessMessage('');
    setModalErrorMessage('');
  }

  useEffect(() => {
    cleanAlerts();
  }, []);


  return (

    <>
      {showModal &&
        (
          <Modal
            // aria-labelledby="contained-modal-title-vcenter"
            aria-labelledby="example-modal-sizes-title-lg"
            fullscreen={true}
            centered
            show={showModal}
            onHide={async () => { contactAction.fetchAllContactsOfAMember(dispatch, await getParams()); cleanAlerts(); setShowModal(false) }}
          >
            <Modal.Header closeButton>
              <Modal.Title> Add new contacts to member </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                modalSuccessMessage && modalSuccessMessage.length > 0 && (
                  <Alert style={{ marginTop: "0.5rem" }} variant="success">{modalSuccessMessage}</Alert>
                )
              }
              {
                modalErrorMessage && modalErrorMessage.length > 0 && (
                  <Alert style={{ marginTop: "0.5rem" }} variant="danger">{modalErrorMessage}</Alert>
                )
              }
              <List
                modelConfirmationTitle="modalconfirmation.titleLink"
                warningOnDelete=""
                modelConfirmationMainButton="modalconfirmation.yesLink"
                customActionName={`add `}
                iconOfDeleteAction={"link"}
                redirect='/contacts/form'
                title={t('overview.miiacontact')}
                isOverview={false}
                showFilterTab={false}
                isEditable={false}
                showFilterButton={false}
                fetchAction={(params) => contactAction.fetchAllExcludingAMember({ dispatch, memberId, params })}
      
                tooltipTextDelete="Add Contact"
                columnNames={[
                  "FULL NAME",
                  "DATE",
                  "DEPARTMENT",
                  "POSITION",
                  "EMAIL",
                  "PHONE NUMBER",
                ]}
                entities={contacts?.data?.map(
                  ({
                    id,
                    attributes: {
                      full_name,
                      createdAt,
                      department,
                      position,
                      email,
                      phone_number,
                    },
                  }) => ({
                    id,
                    columns: [
                      { primary: full_name },
                      { primary: formatDate(createdAt) },
                      { primary: department },
                      { primary: position },
                      { primary: email },
                      { primary: phone_number },
                    ],
                    name: full_name,
                  })
                )}
                fieldsToSort={contactFields}
                canCreateNew={false}
                metadata={contacts?.meta}
                deleteAction={async (id) => contactAction.linkMember({ dispatch, contactId: id, contactParams: await getParams(), memberId, setModalSuccessMessage, setModalErrorMessage })}
                loading={contactListLoading}
              />
            </Modal.Body>
            <Modal.Footer>
              {/* <Button
          variant="secondary"
          className="form-action"
          onClick={() => {
            secondaryAction();
            handleShow(false);
          }}
        >
          {secondaryButton}
        </Button>
        <Button className="form-action form-action-primary" variant="outline-primary" onClick={mainAction}>
          {mainButton}
        </Button> */}
            </Modal.Footer>
          </Modal >
        )
      }
      {
        successMessage && successMessage.length > 0 && (
          <Alert style={{ marginTop: "0.5rem" }} variant="success">{successMessage}</Alert>
        )
      }
      {
        errorMessage && errorMessage.length > 0 && (
          <Alert style={{ marginTop: "0.5rem" }} variant="danger">{errorMessage}</Alert>
        )
      }
      <List
        customNewButtonTitle={"Add contacts"}
        customNewButtonOnClick={async () => {
          cleanAlerts();
          setShowModal(true)
        }}
        customNewButton={true}
        modelConfirmationTitle="modalconfirmation.titleUnlink"
        modelConfirmationMainButton="modalconfirmation.yesUnlink"
        customActionName="remove "
        warningOnDelete=""
        iconOfDeleteAction={"link_off"}
        redirect='/contacts/form'
        title={t('overview.miiacontact')}
        isOverview={false}
        canCreateNew={false}
        showFilterTab={false}
        isEditable={false}
        tooltipTextDelete="Remove Contact"
        columnNames={[
          "FULL NAME",
          "DATE",
          "DEPARTMENT",
          "POSITION",
          "EMAIL",
          "PHONE NUMBER",
        ]}
        entities={contacts?.data?.map(
          ({
            id,
            attributes: {
              full_name,
              createdAt,
              department,
              position,
              email,
              phone_number,
            },
          }) => ({
            id,
            columns: [
              { primary: full_name },
              { primary: formatDate(createdAt) },
              { primary: department },
              { primary: position },
              { primary: email },
              { primary: phone_number },
            ],
            name: full_name,
          })
        )}
        fieldsToSort={contactFields}
        metadata={contactMetadata}
        fetchAction={async (params) => { 
   
          contactAction.fetchAllContactsOfAMember(dispatch, { ...(await getParams()), ...params }) 
        }}
        deleteAction={async (id) => {
          cleanAlerts();
          contactAction.unlinkMember({ dispatch, contactId: id, contactParams: await getParams(), memberId, setErrorMessage, setSuccessMessage })
        }}
        loading={contactListLoading}
      />
    </>
  );
};

export default ContactList;
