/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import "./list.scss";
import { React, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-bootstrap/Pagination";
import Badge from "react-bootstrap/Badge";
import { Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { paginate, populatePagination } from "../../helpers/pagination";
import * as memberAction from "../../redux/actions/member.action";
import * as memberGroupAction from "../../redux/actions/memberGroup.action";
import ModalConfirmation from "../modalconfirmation/ModalConfirmation";
import { useTranslation } from "react-i18next";
import Spinner from "react-bootstrap/Spinner";
import { Button, Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { getTheme } from "../../helpers/select.theme";
import { customStyles } from "../../helpers/select.custom";
import { fetchInitialDuration } from "../../redux/actions/carrousel.action";
import ModalListItems from '../modalListItems/modalListItems';
import debounce from "lodash.debounce";
import {
	DndContext,
	closestCenter,
  useSensor,
  useSensors,
  MouseSensor
} from '@dnd-kit/core';

import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import _ from 'lodash';


import {
	arrayMove,
	SortableContext,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import DraggableRow from "./row";

const ShowPagination = ({
  filterParams = {},
  items = [],
  metadata = {},
  loading,
  showPagination,
  orderedEntities,
  onClickPage
 }) => {

  return (
    <>
      {!loading &&
        showPagination &&
        orderedEntities?.length > 0 && (
          <Pagination className="pagination-container">
            <Pagination.First onClick={() => onClickPage(1)} />
            <Pagination.Prev
              onClick={() =>
                onClickPage(filterParams?.page > 1 ? filterParams?.page - 1 : 1)
              }
            />
            {items}
            <Pagination.Next
              onClick={() =>
                onClickPage(
                  filterParams?.page < metadata?.pagination?.pageCount
                    ? filterParams?.page + 1
                    : metadata?.pagination?.pageCount
                )
              }
            />
            <Pagination.Last
              onClick={() => onClickPage(metadata?.pagination?.pageCount)}
            />
          </Pagination>
        )}
    </>
  )
};

const List = ({
  title = "",
  iconOfDeleteAction,
  searchIsEnabled = true,
  customListError = "",
  warningOnDelete = "The action can not be undone",
  showAlert = false,
  setShowAlert,
  entities = [],
  tooltipTextDelete="",
  tooltipTextUnDelete="",
  redirect = "",
  customNewButton = false,
  customNewButtonOnClick,
  customNewButtonTitle,
  successAlertMessage ="",
  isOverview = true,
  isEditable = true,
  isRemovable = true,
  metadata = null,
  fieldsToSort = [],
  fetchAction = () => {},
  deleteAction = () => {},
  unDeleteAction = () => {},
  showUnDeleteOption = false,
  customActionName = '',
  modelConfirmationTitle = "modalconfirmation.titleDelete",
  modelConfirmationMainButton = "modalconfirmation.yesdelete",

  emptyAction = () => {},
  reOrderAction = () => {},
  member,
  canCreateNew = true,
  columnNames = [],
  showFilterTab = true,
  hasImage = false,
  loading = false,
  showPagination = true,
  showEmptySlot = false,
  showFilterButton = true,
  hasListModal = false,
  bulk,
  tab = null,
  sortIsDisabled = true,
  includeSortByBlocked = false,
}) => {
  const [orderedEntities, setOrderedEntities] = useState([]);
  const [listError, setListError] = useState("");
  const [show, setShow] = useState(false);
  const [showUnDeleteModal, setShowUnDeleteModal] = useState(false);
  const [modalAction, setModalAction] = useState(null);
  const [entityName, setEntityName] = useState("");
  const memberRef = useRef([]);
  const memberGroupRef = useRef([]);
  const pageRef = useRef([]);
  const sortRef = useRef([]);
  const [items, setItems] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [filterTab, setFilterTab] = useState("sort");
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [customFieldSelected, setCustomFieldSelected] = useState("");
  const [enabled, setEnabled] = useState(false);
  const [showListItemsModal, setShowListItemsModal] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState({
    all: true,
    members: [],
    groups: [],
  });
  const [sortDirection, setSortDirection] = useState("ASC");
  const [bulkContext, setBulkContext] = useState({});
  const [filterParams, setFilterParams] = useState({
    fieldTofilter: fieldsToSort?.at(0) || "",
    filter: "",
    fieldToSort: sortIsDisabled ? "id" : "order",
    sortDirection: "desc",
    page: 1,
    pageSize: 10,
    member,
    bulk,
    isFilter: true,
  });

  const NUMBER_OF_SORTING_OPTIONS = includeSortByBlocked ? 4 : 3;

  const [filterParamsForMembers, setFilterParamsForMembers] = useState({
    fieldTofilter: "name",
    filter: "",
    isFilter: true,
  });
  const [filterParamsForMemberGroups, setFilterParamsForMemberGroups] =
    useState({
      fieldTofilter: "title",
      filter: "",
      isFilter: true,
    });

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { memberList, memberGroupsList, meta, filterMeta, carrouselTimeSlide } =
    useSelector((state) => ({
      memberList: state.member.memberFilterList,
      memberGroupsList: state.memberGroup.memberGroupFilterList,
      meta: state.member.memberMetadata,
      filterMeta: state.member.memberFilterMetadata,
      metaGroups: state.memberGroup.memberGroupMetadata,
      carrouselTimeSlide: state.member.carrouselTimeSlide,
    }));

  const carrouselDuration = useSelector(
    (state) => state.carrousel.initialDuration
  );

  useEffect(() => {
    fetchInitialDuration(dispatch);
    setDuration(carrouselDuration);
  }, [carrouselDuration]);

  useEffect(() => {
    const entitiesAndOrderedEntitiesAreDifferent = !_.isEqual(entities, orderedEntities);
    if (entitiesAndOrderedEntitiesAreDifferent) setOrderedEntities(entities);
}, [entities]);

  const [slideDuration, setSlideDuration] = useState(0);
  const [duration, setDuration] = useState(carrouselTimeSlide);

  const handleCloseFilters = () => setShowFilters(false);
  const handleShowFilters = () => setShowFilters(true);
  const handleClick = () => {
    setShowAlert(true);
    const object = {
      timeShown: duration,
    };
    memberGroupAction.setSlideDuration(dispatch, object);
  };

const handleDragEnd = (event) => {
	const {active, over} = event;
  setListError("");
	if (active.id !== over.id) {
		setOrderedEntities((orderEntities) => {
      const oldIndex = orderEntities.findIndex((entity) => entity.id === active.id);
			const newIndex = orderEntities.findIndex((entity) => entity.id === over.id);

      const oldEntity = orderEntities[oldIndex];
      const newEntity = orderEntities[newIndex];
      
      // Check for invalid drag and drop: Draft entities cannot be featured (be among the first 4 greatest order values)
      // Code that check up movement of draft entities
      const orderOfFeatured = metadata?.orderOfFeatured || []
      const entityIsMovedToFeatured = orderOfFeatured.includes(newEntity.order)
      if (oldEntity.draft && entityIsMovedToFeatured) {
        setListError("Draft entities cannot be featured");
        return orderEntities
      }

      // Code that check down movement of draft entities
      const entityIsMovedFromFeatured = orderOfFeatured.includes(oldEntity.order)
      const theFifthEntityIsDraft = metadata?.fifthEntityIsDraft
      if (entityIsMovedFromFeatured && theFifthEntityIsDraft) {
        setListError("Draft entities cannot be featured");
        return orderEntities
      }

      const body ={
          movedEntityId: oldEntity.id,
          movedEntityOrder: oldEntity.order,
          replacedEntityId: newEntity.id,
          replacedEntityOrder: newEntity.order,
        }

      reOrderAction(body, filterParams);

			return arrayMove(orderEntities, oldIndex, newIndex);
		});
	}
}


  const prettifyVariableName = (variableName) => {
    // Replace underscores or dots with spaces

    variableName = variableName.replace(/\./g, ' ');
    variableName = variableName.replace(/_/g, ' ');

    // Add spaces before capital letters
    variableName = variableName.replace(/([A-Z])/g, ' $1');
  
    return variableName.charAt(0).toUpperCase() + variableName.slice(1);
  };

  useEffect(() => {
    memberAction.fetchCarrouselSlides(dispatch);
    carrouselTimeSlide && setSlideDuration(carrouselTimeSlide);
  }, []);

  const handleSlideDuration = (e) => {
    setEnabled(true);
    const durationTime = e.target.value;
    setDuration(durationTime);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setFilterParams((prev) => ({
      ...prev,
      filter: value,
      page: 1,
      member,
      bulk,
      isFilter: false,
    }))
  };

  const debouncedHandleSearch = debounce(handleSearch, 300);

  const handleMemberFilterSearch = (e) => {
    const value = e.target.value;
    setFilterParamsForMembers((prev) => ({
      ...prev,
      filter: value,
      isFilter: true,
    }));

    setFilterParamsForMemberGroups((prev) => ({
      ...prev,
      filter: value,
      isFilter: true,
    }));

    if (value === "") {
      setSelectedMembers({ all: true, members: [], groups: [] });
    }
  };

  const handleSelectMember = (type, memberId) => {
    if (type === "all") {
      setSelectedMembers((prev) => ({
        ...prev,
        all: true,
        members: [],
        groups: [],
      }));
    } else if (type === "member") {
      if (selectedMembers.members.includes(memberId)) {
        setSelectedMembers((prev) => ({
          ...prev,
          all: false,
          members: prev.members.filter((m) => m !== memberId),
        }));

        if (
          selectedMembers.members.length === 1 &&
          selectedMembers.groups.length === 0
        ) {
          setSelectedMembers((prev) => ({
            ...prev,
            all: true,
            members: [],
          }));
        }
      } else {
        setSelectedMembers((prev) => ({
          ...prev,
          all: false,
          members: [...prev.members, memberId],
        }));
      }
    } else if (type === "group") {
      if (selectedMembers.groups.includes(memberId)) {
        setSelectedMembers((prev) => ({
          ...prev,
          all: false,
          groups: prev.groups.filter((m) => m !== memberId),
        }));
        if (
          selectedMembers.groups.length === 1 &&
          selectedMembers.members.length === 0
        ) {
          setSelectedMembers((prev) => ({
            ...prev,
            all: true,
            groups: [],
          }));
        }
      } else {
        setSelectedMembers((prev) => ({
          ...prev,
          all: false,
          groups: [...prev.groups, memberId],
        }));
      }
    }
  };

  const handleSort = (fieldToSort, sortDirection, index) => {
    //check if we selected a field to sort instead of the default options
    if (NUMBER_OF_SORTING_OPTIONS - 1 < index) {
      setCustomFieldSelected(fieldToSort);
    } else {
      setCustomFieldSelected(null);
    }

    if (index !== undefined) {
      sortRef?.current?.forEach((sort) => {
        sort?.classList?.remove("filter-selected-option");
      });
      setSelectedFilter(index);
    }

    setFilterParams((prev) => ({
      ...prev,
      page: 1,
      fieldToSort,
      sortDirection,
      member,
      bulk,
      isFilter: false,
    }));
  };

  const onClickPage = (number) => {
    setFilterParams((prev) => ({
      ...prev,
      page: number,
      member,
      isFilter: false,
    }));
  };

  const handleMemberFilter = () => {
    if (showFilterTab) {
      setFilterParams((prev) => ({
        ...prev,
        memberFilters: selectedMembers,
        isFilter: true,
      }));
    }
  };

  useEffect(() => {
    handleMemberFilter();
  }, [selectedMembers]);

  useEffect(() => {
    const pageCount = metadata?.pagination?.pageCount;

    const items = paginate({
      current: filterParams.page,
      max: pageCount,
    })?.items;

    pageRef.current = [];

    const paginationList = populatePagination(
      items,
      pageRef,
      filterParams,
      onClickPage
    );

    setItems(paginationList);
  }, [
    filterParams?.page,
    metadata?.pagination?.pageCount,
  ]);

  useEffect(() => {
    pageRef?.current.forEach((page) => {
      page?.classList?.remove("pagination-container-selected");
    });
  }, [filterParams.page]);

  useEffect(() => {
    setOrderedEntities([]);
    fetchAction(filterParams);
    if (showFilterTab) {
      memberAction.fetchAll(dispatch, filterParamsForMembers);
      memberGroupAction.fetchAll(dispatch, filterParamsForMemberGroups);
    }
  }, [JSON.stringify(filterParams)]);

  useEffect(() => {
    if (showFilterTab) {
      memberAction.fetchAll(dispatch, filterParamsForMembers);
      memberGroupAction.fetchAll(dispatch, filterParamsForMemberGroups);
    }
  }, [
    JSON.stringify(filterParamsForMembers),
    JSON.stringify(filterParamsForMemberGroups),
  ]);

  const intRx = /\d/,
    integerChange = (event) => {
      if (
        event.key.length > 1 ||
        event.ctrlKey ||
        (event.key === "-" && !event.currentTarget.value.length) ||
        intRx.test(event.key)
      )
        return;
      event.preventDefault();
    };

  for (let input of document.querySelectorAll('input[type="number"][step="1"]'))
    input.addEventListener("keydown", integerChange);


  return (
    <div className="list-container">
      <div className="list">
        {listError && <Alert variant="danger">{listError}</Alert>}
        {customListError && <Alert variant="danger">{customListError}</Alert>}
        {/* // TODO: Refactor to use a single Alert */}
        {showAlert && (
          <>
            {["success"].map((variant) => (
              <Alert key={variant} variant={variant}>
                {successAlertMessage}
              </Alert>
            ))}
          </>
        )}
        <div className="list-header">
          <div className="list-header-main">
            <span className="list-header-title">{title}</span>
            <Badge bg="secondary">{metadata?.pagination.total}</Badge>
          </div>
          <div className="list-header-action-group">
            {!sortIsDisabled && (
              <div className="row-slideDuration">
                <span>Results per page: </span>
                <div style={{ margin: "10px" }}>
                  <Select
                    theme={(theme) => getTheme(theme)}
                    styles={customStyles}
                    onChange={(option) =>
                      setFilterParams((prev) => ({
                        ...prev,
                        pageSize: option.value,
                        page: 1,
                      }))
                    }
                    className="form-select-custom"
                    value={[
                      {
                        value: filterParams.pageSize,
                        label: filterParams.pageSize,
                      },
                    ]}
                    closeMenuOnSelect={false}
                    // onBlur={handleBlur}
                    options={[
                      { value: "10", label: "10" },
                      { value: "20", label: "20" },
                      { value: "50", label: "50" },
                    ]}
                  // isInvalid={errors.contact_role && touched.contact_role}
                  />
                </div>
              </div>
            )}
            {window.location.pathname === "/carrousel" && (
              <div className="row-slideDuration">
                <span>Slide Duration (s)</span>
                <div>
                  <input
                    type="number"
                    className="list-header-input list-header-input-number"
                    onChange={handleSlideDuration}
                    step="1"
                    pattern="^[-\d]\d*$" //only numbers
                    value={duration}
                  />
                </div>
                <Button
                  disabled={!enabled}
                  onClick={handleClick}
                  className="btn-update"
                >
                  Update
                </Button>
              </div>
            )}
            {searchIsEnabled &&
              (
                <div>
                  <input
                    type="text"
                    className="list-header-input"
                    placeholder="Search..."
                    onChange={debouncedHandleSearch}
                  />
                </div>
              )
            }
            {showFilterButton && (
              <span
                onClick={() => handleShowFilters(sortRef)}
                className="list-header-filter-icon material-symbols-outlined"
              >
                filter_alt
              </span>
            )}

            {redirect && canCreateNew && (
              <Link
                to={redirect}
                className="list-header-action"
                variant="outline-primary"
              >
                New
              </Link>
            )}
            {
              customNewButton && (

                <Link
                  onClick={customNewButtonOnClick}
                  className="list-header-action"
                  variant="outline-primary"
                >
                  {customNewButtonTitle}
                </Link>
              )
            }
          </div>
        </div>

        {loading ? (
          <div className="list-loading-spinner-container">
            <Spinner className="list-loading-spinner" animation="border" />
          </div>
        ) : orderedEntities?.length > 0 ? (
          <>
            <DndContext
              collisionDetection={closestCenter}
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={handleDragEnd}
            >
              <div
                  style={
                    isOverview? {maxHeight: '300px', overflowY: 'scroll'}: {}
                  }
                  className={isOverview ? 'scroll-visible' : ''}
              >   
              <table>
                <thead
                      style={
                        isOverview ? { position: 'sticky', top: '0', backgroundColor: '#fff' } : {}
                      }
                    >
                  <tr>
                    {columnNames?.length > 0 &&
                      orderedEntities?.length > 0 &&
                      columnNames?.map((column, index) => (
                        <th key={index}>
                          <span>{column}</span>
                        </th>
                      ))}
                  </tr>
                </thead>

                <tbody>
                  <SortableContext
                    strategy={verticalListSortingStrategy}
                    items={orderedEntities}
                  >
                    {orderedEntities?.map(
                      (
                        { id, image, name, type,redirectAttribute='', columns = [], draft, order, deleted, kcUserId },
                        parentIndex
                      ) => (
                        <DraggableRow
                          tooltipTextDelete={tooltipTextDelete}
                          tooltipTextUnDelete={tooltipTextUnDelete}
                          iconOfDeleteAction={iconOfDeleteAction}
                          metadata={metadata}
                          columns={columns}
                          deleteAction={deleteAction}
                          unDeleteAction={unDeleteAction}
                          showUnDeleteOption={showUnDeleteOption}
                          draft={draft}
                          filterParams={filterParams}
                          hasImage={hasImage}
                          kcUserId={kcUserId}
                          hasListModal={hasListModal}
                          id={id}
                          deleted={deleted}
                          image={image}
                          isEditable={isEditable}
                          isRemovable={isRemovable}
                          member={member}
                          name={name}
                          parentIndex={parentIndex}
                          // redirectAttributes is used to redirect edit of web portal users
                          // from the overview page when the memberId is not always the same
                          // for all users in the list
                          redirect={redirectAttribute || redirect}
                          setBulkContext={setBulkContext}
                          key={parentIndex}
                          setEntityName={setEntityName}
                          setModalAction={setModalAction}
                          setShowListItemsModal={setShowListItemsModal}
                          setShow={setShow}
                          setShowUnDeleteModal={setShowUnDeleteModal}
                          showEmptySlot={showEmptySlot}
                          tab={tab}
                          type={type}
                          sortIsDisabled={sortIsDisabled}
                          order={order}
                        />
                      )
                    )}
                  </SortableContext>
                </tbody>
              </table>
            </div>
            </DndContext>
          </>
        ) : (
          <div className="list-empty-container">
            <span className="material-symbols-outlined">search_off</span>
            <div className="list-empty-feedback">
              <span className="list-empty-text">No results found</span>
              <span className="list-empty-secondary-text">
                Try different search terms
              </span>
            </div>
          </div>
        )}
      {isOverview && (

        <ShowPagination
          loading={loading}
          showPagination={showPagination}
          onClickPage={onClickPage}
          orderedEntities={orderedEntities}
          filterParams={filterParams}
          items={items}
          metadata={metadata}
        />
      )}
      </div>
      {!isOverview && (

        <ShowPagination
          loading={loading}
          showPagination={showPagination}
          onClickPage={onClickPage}
          orderedEntities={orderedEntities}
          filterParams={filterParams}
          items={items}
          metadata={metadata}
        />
      )}
      <Offcanvas show={showFilters} onHide={handleCloseFilters} placement="end">
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Tabs
            className="sort-tabs"
            activeKey={filterTab}
            onSelect={(selectedTab) => setFilterTab(selectedTab)}
          >
            <Tab eventKey="sort" title="Sort" id="sort-tab">
              <div className="filter-general-container">
                <div className="filter-general">
                  <span
                    ref={(element) => {
                      sortRef.current[0] = element;
                    }}
                    onClick={() => handleSort(fieldsToSort?.at(0), "asc", 0)}
                    className={`filter-option ${
                      selectedFilter === 0 ? "filter-selected-option" : ""
                    }`}
                  >
                    Alphabetically
                  </span>
                  <span
                    ref={(element) => {
                      sortRef.current[1] = element;
                    }}
                    onClick={() => handleSort("createdAt", "desc", 1)}
                    className={`filter-option ${
                      selectedFilter === 1 ? "filter-selected-option" : ""
                    }`}
                  >
                    Newest first
                  </span>
                  <span
                    ref={(element) => {
                      sortRef.current[2] = element;
                    }}
                    onClick={() => handleSort("createdAt", "asc", 2)}
                    className={`filter-option ${
                      selectedFilter === 2 ? "filter-selected-option" : ""
                    }`}
                  >
                    Oldest first
                  </span>
                  {includeSortByBlocked && (
                    <span
                      ref={(element) => {
                        sortRef.current[3] = element;
                      }}
                      onClick={() => handleSort("blockedOnKeycloak", "asc", 3)}
                      className={`filter-option ${
                        selectedFilter === 3 ? "filter-selected-option" : ""
                      }`}
                    >
                      Disabled first
                    </span>
                  )}
                </div>
                <div className="filter-parameters">
                  {fieldsToSort?.map((field, index) => (
                    <span
                      ref={(element) => {
                        sortRef.current[index + NUMBER_OF_SORTING_OPTIONS] =
                          element;
                      }}
                      key={index}
                      onClick={() =>
                        handleSort(
                          field,
                          sortDirection,
                          index + NUMBER_OF_SORTING_OPTIONS
                        )
                      }
                      className={`filter-option ${
                        selectedFilter === index + NUMBER_OF_SORTING_OPTIONS
                          ? "filter-selected-option"
                          : ""
                      }`}
                    >
                      {prettifyVariableName(field)}
                    </span>
                  ))}
                </div>
                <div
                  className={`${
                    !customFieldSelected
                      ? "sort-buttons-disabled"
                      : "sort-buttons"
                  }`}
                >
                  <div
                    onClick={() => {
                      if (customFieldSelected) {
                        setSortDirection("ASC");
                        handleSort(customFieldSelected, "ASC");
                      }
                    }}
                    className={`sort-button ${
                      sortDirection === "ASC" ? "sort-button-selected" : ""
                    }`}
                  >
                    <span>ASC</span>
                  </div>
                  <div
                    onClick={() => {
                      if (customFieldSelected) {
                        setSortDirection("DESC");
                        handleSort(customFieldSelected, "DESC");
                      }
                    }}
                    className={`sort-button ${
                      sortDirection === "DESC" ? "sort-button-selected" : ""
                    }`}
                  >
                    <span>DESC</span>
                  </div>
                </div>
              </div>
            </Tab>
            {showFilterTab && (
              <Tab eventKey="filter" title="Filter" id="filter-tab">
                <div className="filter-input">
                  <input
                    type="text"
                    className="list-filter-input"
                    placeholder="Search..."
                    onChange={handleMemberFilterSearch}
                  />
                </div>
                <div className="filter-member-list">
                  <div
                    id="member-0"
                    onClick={() => handleSelectMember("all")}
                    className={`filter-member-row ${
                      selectedMembers?.all ? "filter-member-selected" : ""
                    }`}
                    ref={(element) => {
                      memberRef.current[0] = element;
                    }}
                  >
                    <span>All Members</span>
                    <Badge bg="secondary">
                      {filterMeta?.pagination?.total}
                    </Badge>
                  </div>
                  {memberGroupsList?.data?.length > 0 && (
                    <span className="filter-title">GROUPS</span>
                  )}
                  {memberGroupsList?.data?.map(
                    ({ id, attributes: { title, type } }, index) => (
                      <div
                        id={`group-${id}`}
                        ref={(element) => {
                          memberGroupRef.current[index + 1] = element;
                        }}
                        onClick={() => handleSelectMember("group", id)}
                        key={index}
                        className={`filter-member-row ${
                          selectedMembers?.groups.includes(id)
                            ? "filter-member-selected"
                            : ""
                        }`}
                      >
                        <span className="filter-member-name">{title}</span>
                        <span className="filter-member-type">{type}</span>
                      </div>
                    )
                  )}
                  {memberList?.data?.length > 0 && (
                    <span className="filter-title">MEMBERS</span>
                  )}
                  {memberList?.data?.map(
                    ({ id, attributes: { name, type } }, index) => (
                      <div
                        id={`member-${id}`}
                        ref={(element) => {
                          memberRef.current[index + 1] = element;
                        }}
                        onClick={() => handleSelectMember("member", id)}
                        key={index}
                        className={`filter-member-row ${
                          selectedMembers?.members.includes(id)
                            ? "filter-member-selected"
                            : ""
                        }`}
                      >
                        <span className="filter-member-name">{name}</span>
                        <span className="filter-member-type">{type}</span>
                      </div>
                    )
                  )}
                </div>
              </Tab>
            )}
          </Tabs>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalListItems
        show={showListItemsModal}
        handleShow={setShowListItemsModal}
        bulkContext={bulkContext}
      />

  <ModalConfirmation
        title={t("modalconfirmation.titleUnblock")}
        question={t("modalconfirmation.unblockquestion", {
          entity: entityName || "this entity",
        })}
        mainAction={modalAction}
        secondaryAction={() => {}}
        mainButton={t("modalconfirmation.yesUnblock")}
        secondaryButton={t("modalconfirmation.cancel")}
        handleShow={setShowUnDeleteModal}
        show={showUnDeleteModal}
      />
      <ModalConfirmation
        title={t(modelConfirmationTitle)}
        question={t("modalconfirmation.deletequestion", {
          entity: entityName || "this entity",
          action: customActionName || (showUnDeleteOption ? "disable" : "delete"),
          warning: warningOnDelete
        })}
        mainAction={modalAction}
        secondaryAction={() => {}}
        mainButton={t(modelConfirmationMainButton)}
        secondaryButton={t("modalconfirmation.cancel")}
        handleShow={setShow}
        show={show}
      />
    </div>
  );
};

export default List;
